.register-webdoor {
    background-image: url("../images/logo.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  
    display: table-cell;
    vertical-align: middle;
  }
  
  .register-container {
    display: table;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  
  .button {
    width: 100%;
    background-color: #26aae2;
    border-radius: 20px;
  }
  
  .btn-primary {
    background-color: #26aae2;
  }
  
  .outside-bar{
    text-align: center;
    position: relative;
    bottom: -40px;
  }
  
  .outside-bar .btn{
    padding: 0px !important;
    margin: 0px;
  }
  
  .outside-bar .dropdown-toggle,
  .outside-bar a.white{
    color: white;
    text-decoration: none;
  }
  
  .outside-bar .dropdown-toggle:focus{
    box-shadow: none;
  }
  
  .outside-bar button:hover,
  .outside-bar a:hover{
    color: white;
    text-decoration: none;
  }
  
  .register .content form {
    padding: 0px;
  }
  
  .register .content form .input-group .col{
    padding: 0px;
  }
  
  .footer{
    text-align: center;
    padding: 20px;
    margin-bottom: -30px;
    left: -18px;
    position: relative;
    width: calc(100% + 60px);
    background-color: #f5f5f5;
    border-top: 1px solid #ddd;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
  }
  
  
  
  @media only screen and (max-width: 640px) {
    .register .content {
      margin-left: auto;
      margin-right: auto;
  
      max-width: 87vh;
      background-color: aliceblue;
      padding: 10px 10px 0px 10px !important;
      border-radius: 20px;
      opacity: 0.9;
    }
  
    .contry-code-desktop{
      display: none;
    }
  
    .ig-item-2{
      width: 70%;
    }
  
    .ig-item-3{
      width: 30%;
    }
  
    .outside-bar{
      text-align: center;
      position: relative;
      bottom: -55px;
    }
  
    .footer{
      text-align: center;
      padding: 20px;
      margin-bottom: -30px;
      left: 2px;
      position: relative;
      width: calc(100% + 20px);
      background-color: #f5f5f5;
      border-top: 1px solid #ddd;
      border-bottom-right-radius: 8px;
      border-bottom-left-radius: 8px;
    }
  }
  
  @media only screen and (min-width: 641px) {
    .register .content {
      margin-left: auto;
      margin-right: auto;
    
      max-width: 75vh;
      background-color: aliceblue;
      padding: 30px 30px 0px 30px !important;
      border-radius: 20px;
      opacity: 0.9;
    }
  
    .ig-item-1{
      width: 30%;
    }
  
    .ig-item-2{
      width: 50%;
    }
  
    .ig-item-3{
      width: 20%;
    }
  
    .contry-code-mobile{
      display: none;
    }
  }
  
  .register {
    padding: 60px 0;
  }  