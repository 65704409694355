.footerWrapper {
    display: flex;
    justify-content: space-between;
}

.footerSocialMedia {
    align-items: center;
    display: flex;
}

.footerSocialMedia ul {
    display: flex;
    align-items: center;
}

.footerSocialMedia img {
    max-width: 40px;
}

.footerSocialMedia ul li {
    margin-left: 30px;
}

.email-template-header {
	width: 100%;
	padding: 20px;
    background: #26AAE2;
    height: 150px;
}

.logoContainer {
	width: 100%;
    border-color: #26AAE2 !important;
}

.content-email-template {
    padding: 6rem 6rem 15rem 6rem;
}

.content-p {
    margin-top: 2rem;
}

.confirmation-id {
    font-size: 20px;
    font-weight: bolder;
}

.jaw-team {
    font-weight: bolder;
}

.logo {
	max-height:600px;
}

.mosaic-img {
    max-width: 100%;
}

.footer-email {
	width: 100%;
    height: 15rem;
	background-color: #313335;
	color: white !important;
	padding: 50px;
}

.footer-email p {
    font-size: 12px;
}

.footer-email ul {
    list-style-type: none;
}

.footerNav {
    margin-bottom: 10px;
    transform: translateY(100%);
}

.footerNav p {
    font-size: 20px;
}


@media only screen and (max-width: 600px) {
    .footerWrapper {
        display: block;
        justify-content: space-between;
    }

    .content-email-template {
        padding-left: 20px;
    }

    .footer-email {
        width: 100%;
        height: 15rem;
        background-color: #313335;
        color: white !important;
        padding: 10px;
    }

    .footerSocialMedia img {
        max-width: 20px;
    }

    .logo {
        height: 80px;
    }

    .footerNav {
        margin: 20px 0 0 0;
        transform: translateY(0%);
    }
    
    .footerNav p {
        font-size: small;
        white-space: nowrap;
        margin-bottom: 0;
    }

    .footerSocialMedia ul li {
        margin: 20px 30px 0 0;
    }

    .mosaic-img {
        width: 100%;
        height: 20vh;
    }
}