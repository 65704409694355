.welcome-form-label {
    display: flex;
    flex-direction: column;
    text-align: center;
    padding-top: 1rem;
  }
  
  .welcome-bold-paragraph {
    margin-bottom: 0;
    font-weight: bolder;
    font-size: 1.3rem;
  }

  .swal2-actions {
    gap: 2rem !important;
  }