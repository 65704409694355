body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --color-primary: #26AAE2;
  --color-primary-light: #26AAE28D;
  --color-blue: #189AD8;
  --color-gray-dark: #292929;
  --color-gray: #A3A0A0;
  --color-gray-light: #E3E3E34D;
  --color-black-light: #00000080;
  --color-green: #2DBE6A;
  --text-color-gray: #707070;
  --text-color-gray-dark: #463939;
  --border-radius-small: 3px;
  --border-radius-medium: 10px;
  --border-radius-large: 20px;
  --font-size-webdoor-title: 79px;
  --font-size-page-title: 40px;
}

.button {
  background-color: var(--color-primary);
  color: #ffffff;
  border: 1px solid var(--color-primary);
  text-transform: uppercase;
  padding: 10px 30px;
  outline: none;
  cursor: pointer;
  border-radius: var(--border-radius-medium);
}

.button.round {
  border-radius: 2rem;
}

.spinner-right-margin{
  margin-right: 10px;
}

.spinner-left-margin{
  margin-left: 10px;
}