@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

:root {
	--color1: #656261;
	--color2: #afafaf;
	--color3: #00ace5;
	--font: "Roboto", sans-serif;
}

.defaults {
	font-family: var(--font) !important;
    color: var(--color1) !important;
}

html{
    background-color: #f2f2f2;
}

.header {
	background-color: white;
	width: 100%;
	height: 80px;
}

.logo {
	height: 50px;
}

.background {
    background-color: #f2f2f2;
	width: 100%;
	height: 100%;
	padding-top: 30px;
	padding-bottom: 30px;
}

.primaryText,
.secondaryText {
	font-weight: bold;
}

.primaryText {
	color: var(--color1) !important;
}

.secondaryText {
	color: var(--color2) !important;
}



.formContainer {
	margin-top: 10px;
	padding: 30px;
	background-color: white;
	border-radius: 5px;
}

.btnPrimary{
	height: 60px;
    /* border-radius: .6rem !important; */
	font-weight: bolder;
    padding: 4px !important;
	color: white !important;
	background: #0070ba !important;
}

.btnRadiusRounded{
    border-radius: 2.3rem !important;
}

.product{
	background-color: var(--color3);
	border-radius: .5em;
	margin-top: 5px !important;
	margin-bottom: 5px;
}

@keyframes productIsRequiredAnimation {
	0% {margin: 0px; border: 5px solid transparent;  border-image: linear-gradient(to bottom right, #b827fc 0%, #2c90fc 25%, #b8fd33 50%, #fec837 75%, #fd1892 100%); border-image-slice: 1; border-radius: .5em;}
	10% {margin: 1px 0px 1px 0px; border: 4px solid transparent; border-image: linear-gradient(to bottom right, #2c90fc 0%, #b8fd33 25%, #fec837 50%, #fd1892 75%,  #b827fc 100%); border-image-slice: 1; border-radius: .5em;}
	20% {margin: 2px 0px 2px 0px; border: 3px solid transparent; border-image: linear-gradient(to bottom right, #b8fd33 0%, #fec837 25%, #fd1892 50%,  #b827fc 75%, #2c90fc 100%); border-image-slice: 1; border-radius: .5em;}
	30% {margin: 3px 0px 3px 0px; border: 2px solid transparent; border-image: linear-gradient(to bottom right, #fec837 0%, #fd1892 25%, #b827fc 50%,  #2c90fc 75%, #b8fd33 100%); border-image-slice: 1; border-radius: .5em;}
	40% {margin: 4px 0px 4px 0px; border: 1px solid transparent; border-image: linear-gradient(to bottom right, #fd1892 0%, #b827fc 25%, #2c90fc 50%, #b8fd33 75%, #fec837 100%); border-image-slice: 1; border-radius: .5em;}
	50% {margin: 0px; border: 5px solid transparent; border-image: linear-gradient(to bottom right, #b827fc 0%, #2c90fc 25%, #b8fd33 50%, #fec837 75%, #fd1892 100%); border-image-slice: 1; border-radius: .5em;}
	60% {margin: 1px 0px 1px 0px; border: 4px solid transparent; border-image: linear-gradient(to bottom right, #b827fc 0%, #2c90fc 25%, #b8fd33 50%, #fec837 75%, #fd1892 100%); border-image-slice: 1; border-radius: .5em;}
	70% {margin: 2px 0px 2px 0px; border: 3px solid transparent; border-image: linear-gradient(to bottom right, #2c90fc 0%, #b8fd33 25%, #fec837 50%, #fd1892 75%,  #b827fc 100%); border-image-slice: 1; border-radius: .5em;}
	80% {margin: 3px 0px 3px 0px; border: 2px solid transparent; border-image: linear-gradient(to bottom right, #b8fd33 0%, #fec837 25%, #fd1892 50%,  #b827fc 75%, #2c90fc 100%); border-image-slice: 1; border-radius: .5em;}
	90% {margin: 4px 0px 4px 0px; border: 1px solid transparent; border-image: linear-gradient(to bottom right, #fec837 0%, #fd1892 25%, #b827fc 50%,  #2c90fc 75%, #b8fd33 100%); border-image-slice: 1; border-radius: .5em;}
	100% {margin: 0px; order: 5px solid transparent; border-image: linear-gradient(to bottom right, #fd1892 0%, #b827fc 25%, #2c90fc 50%, #b8fd33 75%, #fec837 100%); border-image-slice: 1; border-radius: .5em;}
  }

.productIsRequired{
	/*animation: productIsRequiredAnimation 5s linear infinite;*/
	border: 3px solid;
	border-color: var(--danger);
}

.product p{
	margin: 0px;
	padding: 0px;
	color: white;
	font-weight: bold;
}

.productLabel {
	margin-left: 37px;
}

.product input{
	margin: 0 !important;
}

.product input:checked {
	background-color: #ffc107 !important;
	border-color: #ffc107 !important;
}

.creditCardBrandLogo img{
	max-height: 36px;
}


@media only screen and (max-width: 811px) {
	.contentGrid{
		display: none;
	}

	.product{
		padding-bottom: 10px;
	}

	.productLabel{
		margin-left: 0;
	}
}