.otpForm {
  display: flex;
  flex-direction: column;
  color: #282828;
  gap: 16px;
}

.phoneNumberInputGroup {
  display: flex;
  flex-direction: row;
}

.phoneNumberInputGroup .countryCode {
  display: flex;
  flex-direction: row;
  width: 150px;
}

.phoneNumberInputGroup .phoneNumber {
  display: flex;
  flex-direction: row;
  width: calc(100% - 230px);
}

.phoneNumberInputGroup .sendCodeButton {
  width: 80px;
}

@media only screen and (max-width: 811px) {
  .phoneNumberInputGroup .countryCode {
    width: 200px;
  }

  .phoneNumberInputGroup .phoneNumber {
    width: calc(100% - 280px);
  }

  .phoneNumberInputGroup .sendCodeButton {
    width: 80px;
  }
}

@media only screen and (max-width: 550px) {
  .phoneNumberInputGroup {
    gap: 16px;
  }

  .phoneNumberInputGroup .countryCode {
    width: 100%;
  }

  .phoneNumberInputGroup .phoneNumber {
    width: calc(100% - 94px);
  }

  .phoneNumberInputGroup .sendCodeButton {
    width: 80px;
  }
}