@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

:root {
	--color1: #656261;
	--color2: #afafaf;
	--color3: #00ace5;
	--font: "Roboto", sans-serif;
}

.defaults {
	font-family: var(--font) !important;
    color: var(--color1) !important;
}

html{
    background-color: #f2f2f2;
}

.header {
	background-color: white;
	width: 100%;
	height: 80px;
}

.logo {
	height: 50px;
}

.background {
    background-color: #f2f2f2;
	width: 100%;
	height: 100%;
	padding-top: 30px;
	padding-bottom: 30px;
}

.primaryText,
.secondaryText {
	font-weight: bold;
}

.primaryText {
	color: var(--color1) !important;
}

.secondaryText {
	color: var(--color2) !important;
}

@media only screen and (max-width: 811px) {
	.contentGrid{
		display: none;
	}
}

.formContainer {
	margin-top: 10px;
	padding: 30px;
	background-color: white;
	border-radius: 5px;
}

.linkLogin {
	color: var(--color3) !important;
	text-decoration: none;
	font-weight: bold;
}

.linkLogin:hover {
	text-decoration: none;
}

.btnPrimary,
.btnOutlinePrimary{
    border-radius: 2.3rem !important;
	font-weight: bold;
    padding: 4px !important;
}

.btnPrimary {
	color: white !important;
	background-color: var(--color3) !important;
}

.btnOutlinePrimary{
    border-color: var(--color3) !important;
    border-width: 3px;
    color: var(--color3) !important;
}

.btnOutlinePrimary img{
    max-height: 20px;
    margin-left: 30px;
}

.btnOutlinePrimary:hover{
    background-color: transparent !important;
}

.separator {
	display: flex;
	align-items: center;
	text-align: center;
}

.separator::before,
.separator::after {
	content: "";
	flex: 1;
	border-bottom: 1px solid var(--color2);
}

.separator:not(:empty)::before {
	margin-right: 0.25em;
}

.separator:not(:empty)::after {
	margin-left: 0.25em;
}

.paddingTermCheck{
    padding-left: 20px;
}

.checkBorder input{
    border: 2px solid var(--color3);
    border-radius: 0 !important;
}

.checkBorder label.is-invalid{
	color: rgba(var(--bs-danger-rgb),var(--bs-text-opacity)) !important;
}

.requiredAcceptTermLink{
	color: #ffc107 !important;
}

.requiredAcceptTermLink:hover{
	color: #FFCA2C !important;
}

.iconsFormRegistration {
	display: flex;
	grid-template-columns: 1fr 16fr;
}

.passwordShown {
	cursor: pointer;
}

.progress {
	width: 6px !important;
}