.Login {
  display: flex;
  width: 100%;
  min-height: 100vh;
  color: #FFF;
}

.Login .background {
  display: flex;
  flex-direction: row;
  background-image: url("../images/webdoor.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.Login .leftPanel {
  width: 60vw;
  background: linear-gradient(270deg, rgba(40, 40, 40, 0) 0%, #000000 100%);

  display: flex;
  justify-content: right;
}

.Login .leftPanelContent {
  display: flex;
  flex-direction: column;
  margin: auto;
  max-width: 650px;
  gap: 8px;
}

.Login .leftPanelTitle {
  font-size: 64px;
  font-weight: 700;
  line-height: 74px;

}

.Login .leftPanelSubtitle {
  font-size: 36px;
  font-weight: 300;
  line-height: 50px;
}

.Login .rightPanel {
  width: 40vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  padding: 56px;
  background: linear-gradient(268.93deg, rgba(0, 0, 0, 0.95) 9.35%, rgba(40, 40, 40, 0) 213.95%);
}

.Login .logo-desktop {
  margin-bottom: 50px;
  width: 230px;
  height: 100%;
}

.Login .logo-mobile {
  display: none;
}

.Login .form {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  height: 100%;
  justify-content: center;
}


.Login .formFooter {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.Login .formFooter a {
  color: #FFFFFF;
  text-decoration: none;
}

.Login .formFooter .dropdown a {
  color: #282828;
  text-decoration: none;
}

.Login .formFooter .dropdown-toggle {
  color: #FFFFFF;
  text-decoration: none;
}

.Login .signUpCard {
  display: flex;
  flex-direction: column;
  gap: 8px;
  background-color: #28282880;
  padding: 16px;
  border-radius: 8px;
}

.Login .signUpCard .cardTitle {
  color: #00ABE3;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0em;
}

.Login .signUpCard .cardDescription {
  color: #FFFFFF;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0em;
}

.Login .signUpCard .cardInfo {
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
  color: rgb(255, 255, 255, 0.5);
}

@media only screen and (max-width: 811px) {
  .Login .background {
    flex-direction: column;
  }

  .Login .logo-desktop {
    display: none;
  }

  .Login .logo-mobile {
    display: flex;
    margin: 30px auto 50px auto;
    width: 180px;
  }

  .Login .leftPanel {
    width: 100vw;
  }

  .Login .rightPanel {
    width: 100vw;
    background: #000;
  }

  .Login .leftPanelContent {
    display: flex;
    flex-direction: column;
    margin: auto auto 25px auto;
    max-width: 650px;
    gap: 8px;
  }

  .Login .leftPanelTitle {
    font-size: 56px;
    font-weight: 700;
    line-height: 64px;
    text-align: center;
  }

  .Login .leftPanelSubtitle {
    font-size: 32px;
    font-weight: 300;
    line-height: 45px;
    text-align: center;
  }
}

@media only screen and (max-width: 550px) {
  .Login .logo-mobile {
    margin: 30px auto 30px auto;
    width: 113px;
  }

  .Login .rightPanel {
    padding: 24px;
  }

  .Login .leftPanelContent {
    padding: 0px 24px 0 24px;
  }

  .Login .leftPanelTitle {
    font-size: 32px;
    font-weight: 700;
    line-height: 37px;
    text-align: center;
  }

  .Login .leftPanelSubtitle {
    font-size: 20px;
    font-weight: 300;
    line-height: 28px;
    text-align: center;
  }
}