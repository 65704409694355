.brands {
    display: inline-block;
    margin-right: 15px;
    padding: 0px;
    list-style: none;
    text-align: center;
    cursor: pointer;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, .125);
    border-radius: .25rem;
}

.brands input:hover {
    cursor: pointer;
}

.brands img {
    max-width: 60px;
}

.brands input {
    position: initial;
    margin-top: 0px;
    margin-left: 0px;
}

.brands:hover {
    background-color: #E0E0E0;
}

.brands.active,
.brands.active:hover{
    background-color: #d4edda;
    border-color: #c3e6cb;
}

.formboxWrapper {
    background-color: var(--color-primary-light);
    padding: 20px 40px;
    border-radius: 20px;
}

.formboxWrapper h5 {
    text-align: center;
    color: #ffffff;
    text-transform: uppercase;
    font-weight: 800;
}

.formboxWrapper h5 + h5 {
    margin-bottom: 20px;
}

.formboxWrapper input {
    text-align: center;
}

.renew-subscription-product-plan-card {
    justify-content: center;
}

@media only screen and (max-width: 1180px) {
    .renew-subscription-product-plan-card {
        display: grid;
        gap: 0.5rem;
        width: auto !important;
    }

    .renew-subscription-creditcard {
        gap: 1rem !important;
    }
}