.page {
    background-color: #282828;
    width: 100vw;
    min-height: 100vh;
    color: #fff;
    font-family: 'Roboto', sans-serif;
    font-feature-settings: normal;
    font-variation-settings: normal;
}

.pageLink {
    color: #26AAE2;
    text-decoration: none;
}

.navbar {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: 90rem;
    padding: 1.75rem 0rem;
}

.logo {
    width: 107px;
    height: 45px;
}

.hero {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 10rem;
    padding: 0rem 7.5rem;
    background-color: rgba(40, 40, 40, .6);
}

.heroBackground {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    min-height: 100vh;
    margin-bottom: 10rem;
    background-image: url("../images/webdoor.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.pageContainer {
    display: flex;
    flex-direction: column;
    padding: 2rem 7.5rem;
    margin-top: 100vh;
}

.pageRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.pageColumn {
    display: flex;
    flex-direction: column;
}

.pageColumn50 {
    display: flex;
    flex-direction: column;
    width: 50%;
}

.title {
    line-height: 3.5rem;
    font-size: 3.5rem;
    margin-top: 1rem;
    margin-bottom: 32px;
}

.textContent {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin-bottom: 2rem;
    margin-right: 3rem;
}

.paragraph {
    font-size: 1rem;
    line-height: 1.75rem;
}

.platformGrid {
    display: grid;
    gap: 1rem;
    grid-template-columns: auto auto auto;
    margin-top: 3rem;
}

.item {
    display: flex;
    flex-direction: column;
    border: 1px solid #fff;
    border-radius: 0.75rem;
    padding: 1.25rem;
    width: 100%;
    gap: 3.5rem;
}

.platform {
    display: flex;
    flex-direction: row;
    gap: 1.25rem;
}

.platformName {
    font-size: 1rem;
    line-height: 1.5rem;
    width: 100%;
}

.sectionTitle {
    font-size: 2.5rem;
    margin-bottom: 2rem;
}

.backgroundVideo {
    max-width: 100%;
}

.pageFooter {
    display: flex;
    padding: 3.5rem 7.5rem;
}

@media only screen and (max-width: 1024px) {
    .hero {
        padding: 0px 1rem;
        margin-bottom: 6.25rem;
        width: 100%;
        height: 100%;
    }

    .navbar {
        padding: 1.75rem 0rem;
    }

    .hero {
        position: relative;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(40, 40, 40, 1);
        margin-bottom: 2rem;
    }

    .heroBackground {
        display: none;
    }

    .pageContainer {
        padding: 0px 1rem;
        margin-top: 0rem;
    }

    .title {
        line-height: 2.625rem;
        font-size: 2.5rem;
        margin-top: 1rem;
    }

    .textContent {
        gap: 2rem;
        margin-right: 0px;
    }

    .platformGrid {
        display: grid;
        gap: 1rem;
        grid-template-columns: auto;
        margin-top: 3rem;
    }

    .sectionTitle {
        font-size: 2rem;
    }

    .pageColumn50 {
        display: flex;
        flex-direction: column;
        width: 100%;
    }
}