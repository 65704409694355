.qrCodeScreen {
    display: flex;
    width: 100vw;
    height: 100vh;
    background-color: #282828;
    color: #FFFFFF;
    align-items: center;
    justify-content: center;
}

.qrCodeScreen .qrCodecontainer {
    display: flex;
    flex-direction: row;
    max-width: 60vw;
    padding: 32px;
    margin: auto;
    gap: 64px;
}

.qrCodeScreen .qrCodeColumn {
    display: flex;
    align-items: center;
    justify-content: center;
}

.qrCodeScreen .qrCode {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #FFFFFF;
    padding: 1vw;
    max-width: 20vw;
    max-height: 20vw;
}

.qrCodeScreen .codeColumn {
    display: flex;
    flex-direction: column;
}

.qrCodeScreen .codeColumn .title {
    font-size: 2.4vw;
}

.qrCodeScreen .codeColumn .content {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.qrCodeScreen .codeColumn .content p {
    font-size: 1.6vw;
}

.qrCodeScreen .codeColumn .content .code {
    font-weight: 700;
    font-size: 3.2vw;
    letter-spacing: 10px;
}

.loading {
    width: 100vw;
    height: 100vh;
    background-color: #282828;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 3.2vw;
    color: #61dafb;
}