.pageTitle {
    font-size: var(--font-size-page-title);
    text-align: center;
    max-width: 475px;
    padding: 0px 10px;
}

.pageSubtitle {
    padding: 0px 10px;
}

.formWrapper {
    background-color: #ffffff;
    width: 430px;
    padding: 20px 30px;
    border-radius: 20px;
    margin-top: 50px;
}

.formWrapper form {
    display: flex;
    flex-direction: column;
}

.formInput {
    margin-bottom: 20px;
    border-radius: 3px;
    font-weight: 500;
    font-size: 15px;
    padding: 15px 20px;
}

@media screen and (min-width: 769px) {
    .formInput::-webkit-input-placeholder {
        color: var(--color-gray);
    }

    .formInput:-moz-placeholder {
        /* Mozilla Firefox 4 to 18 */
        color: var(--color-gray);
    }

    .formInput::-moz-placeholder {
        /* Mozilla Firefox 19+ */
        color: var(--color-gray);
    }

    .formInput:-ms-input-placeholder {
        /* Internet Explorer 10+ */
        color: var(--color-gray);
    }
}

.buttonGroup {
    margin-top: 15px;
}

.btn{
    padding: 10px 30px !important;
}

.btn-round {
    border-radius: 2rem !important;
}

.btn-primary {
    border-color: var(--color-primary) !important;
    background-color: var(--color-primary) !important;
}

.outlined {
    border: 1px solid var(--color-primary) !important;
    background-color: transparent !important;
    color: var(--color-primary) !important;
}