.section-webdoor {
  /* min-height: 100vh; */
  background-image: url("../images/logo.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: table-cell;
  vertical-align: middle;
}

.login-container {
  display: table;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.button {
  width: 100%;
  background-color: #26aae2;
  border-radius: 20px;
}

.btn-primary {
  background-color: #26aae2;
}

.btn-change-password-with-celphone {
  display: flex;
  gap: 2rem;
}

.white-dropdown-text button {
  color: white !important;
}

.icons-form-registration {
  grid-template-columns: 10fr 0fr;
  display: grid;
  margin-bottom: 1rem;
}

.change-password-shown {
  cursor: pointer;
}

@media only screen and (max-width: 400px) {
  .content-change-password-with-email form {
    margin-left: 15px;
    margin-right: 15px;

    max-width: 75vh;
    background-color: aliceblue;
    padding: 30px;
    border-radius: 20px;
    opacity: 0.9;
  }

  .btn-change-password-with-celphone {
    display: flex;
    flex-direction: column;
    grid-gap: 0.5rem;
  }
}

@media only screen and (min-width: 400px) {
  .content-change-password-with-email form {
    margin-left: auto;
    margin-right: auto;

    max-width: 75vh;
    background-color: aliceblue;
    padding: 30px;
    border-radius: 20px;
    opacity: 0.9;
  }
}

.content-change-password-with-email {
  padding: 60px 0;
}