.bg-container {
    background-image: url("../images/webdoor.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: table;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.container{
    margin-top: 20px;
    margin-bottom: 20px;
    background-color: white;
    border-radius: 20px;
    padding: 20px 30px;
}

hr{
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid rgba(0,0,0,.1);
}

/* o tamanho da tela tem que ser em ordem decrescente */

@media only screen 
  and (max-device-height: 1366px) {
    .max-window-heigth{
        max-height: 1000px;
        overflow: auto;
    }
}

/*Monitor*/
@media only screen 
  and (max-device-height: 1080px) {
    .max-window-heigth{
        max-height: 650px;
        overflow: auto;
    }
}

@media only screen 
  and (max-device-height: 1024px) {
    .max-window-heigth{
        max-height: 700px;
        overflow: auto;
    }
}

/*Notbook*/
@media only screen 
  and (max-device-height: 870px) {
    .max-window-heigth{
        max-height: 450px;
        overflow: auto;
    }
}

@media only screen 
  and (max-device-height: 812px) {
    .max-window-heigth{
        max-height: 500px;
        overflow: auto;
    }
}

@media only screen 
  and (max-device-height: 768px) {
    .max-window-heigth{
        max-height: 468px;
        overflow: auto;
    }
}

@media only screen 
  and (max-device-height: 736px) {
    .max-window-heigth{
        max-height: 440px;
        overflow: auto;
    }
}

@media only screen 
  and (max-device-height: 667px) {
    .max-window-heigth{
        max-height: 380px;
        overflow: auto;
    }
}

@media only screen 
  and (max-device-height: 640px) {
    .max-window-heigth{
        max-height: 340px;
        overflow: auto;
    }
}

@media only screen 
  and (max-device-height: 600px) {
    .max-window-heigth{
        max-height: 320px;
        overflow: auto;
    }
}

@media only screen 
  and (max-device-height: 568px) {
    .max-window-heigth{
        max-height: 300px;
        overflow: auto;
    }
}

@media only screen 
  and (max-device-height: 540px) {
    .max-window-heigth{
        max-height: 264px;
        overflow: auto;
    }
}

@media only screen 
  and (max-device-height: 414px) {
    .max-window-heigth{
        max-height: 160px;
        overflow: auto;
    }
}

@media only screen 
  and (max-device-height: 375px) {
    .max-window-heigth{
        max-height: 120px;
        overflow: auto;
    }
}

@media only screen 
  and (max-device-height: 360px) {
    .max-window-heigth{
        max-height: 110px;
        overflow: auto;
    }
}

@media only screen 
  and (max-device-height: 320px) {
    .max-window-heigth{
        max-height: 80px;
        overflow: auto;
    }
}